import { useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { getFolder, getFolderInOrderByUser, getImagesUrl, getOderList } from "../api";
import { setSelectedFolder as setSelectedFolderToStorage, getSelectedRoute } from "../utils";
import Loading from "../components/Loading";
import { CurrentPageMap, CustomImage, ImageMap, Log, Order, OrderMap } from "../interface";
import { useRecoilValue } from "recoil";
import { userState } from "../state";
import StandardImageList from "../components/StandardImageList";
import ModalEditImage from "../components/ModalEditImage";
import UserOrderHistory from "../components/UserOderHistory";
import UserOrderAgain from "../components/UserOrderAgain";
import UserOrderHistoryAll from "../components/UserOderHistoryAll";
import { NONE_GROUP, ROLES } from "../constant";


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const pageSize = 40;

const EditImage = ({defaultEditedImageUrl, groupName}: {defaultEditedImageUrl?: string, groupName: string}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const selectedRoute = getSelectedRoute();
  const userProfile = useRecoilValue(userState);
  const [images, setImages] = useState<ImageMap>({});
  const [folders, setFolders] = useState<string[]>([]);
  const [imageUrlForEdit, setImageUrlForEdit] = useState<string>("");
  const [imageIndex, setImageIndex] = useState(0);
  const [totalData, setTotalData] = useState<CurrentPageMap>({});
  const [currentPage, setCurrentPage] = useState<CurrentPageMap>({});
  const [selectedFolder, setSelectedFolder] = useState<string>("");
  const [tab, setTab] = useState(0);
  const [countEdited, setCountEdited] = useState(0);
  const [orders, setOrders] = useState<OrderMap>({})
  const [justOrder, setJustOrder] = useState<string[]>([])
  const [defaultImage, setDefaultImage] = useState<string>("")
  const [allFolderInOrder, setAllFolderInOrder] = useState<string[]>([])

  useEffect(()=>{
    if(selectedRoute && userProfile.userId) getAllFolderByUser()
  },[userProfile.userId, selectedRoute])

  useEffect(()=>{
    if(defaultEditedImageUrl !== undefined && defaultEditedImageUrl !== ""){
      setTab(2)
      setDefaultImage(defaultEditedImageUrl)
    }
  },[defaultEditedImageUrl])

  useEffect(() => {
    if (selectedRoute && selectedRoute !== "") {
      getFolders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoute]);


  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.scrollHeight) {
        folders.forEach((folder) => {
          if ((currentPage[folder] * pageSize) < totalData[folder]) {
            loadMore(folder);
          }
        });
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [folders, currentPage, totalData]);


  const getAllFolderByUser = async (): Promise<void> => {
    const allFolders = await getFolderInOrderByUser(selectedRoute,userProfile.userId);
    setAllFolderInOrder(allFolders)
  }

  const getFolders = async (): Promise<void> => {
    setIsLoading(true);
    const folders = await getFolder(selectedRoute, userProfile.role);
    if (folders.length > 0) {
      downloadImages(folders);
      downloadOrders(folders);
      setTimeout(() => {
        setFolders(folders);
        setIsLoading(false);
      }, 1500);
    } else {
      setIsLoading(false);
    }
  };

  const downloadImages = async (folders: string[]) => {
    if (selectedRoute) {
      if (folders.length > 0) {
        const imageMap: ImageMap = {};
        folders.map(async (folder) => {
          if (folder) {
            setCurrentPage({ ...currentPage, [folder]: 1 });
            const newImages = await downloadImageByFolder(folder, 1);
            imageMap[folder] = newImages;
            
          }
        });
        setImages(imageMap);
        
      }
    }
  };

  const downloadImageByFolder = async (
    folder: string,
    page: number
  ): Promise<CustomImage[]> => {
    const { images, total } = await getImagesUrl(selectedRoute, folder, pageSize, page, userProfile.userId, userProfile.role == ROLES.ADMIN, userProfile.groupId || NONE_GROUP);
    const newImages = images.map((item: string) => {
      const newImageUrl = item.replace(
        "http://localhost:8002",
        "https://sgjshop-api.dodev.me"
      );
      return {
        original: newImageUrl,
        caption: newImageUrl,
      };
    });
    setTotalData({ ...totalData, [folder]: total });
    return newImages;
  };


  const downloadOrders = async (folders: string[]) => {
    if (selectedRoute) {
      if (folders.length > 0) {
        const orderMap: OrderMap = {};
        folders.map(async (folder) => {
          if (folder) {
            const newOrders = await downloadOrderByFolder(folder)
            orderMap[folder] = newOrders;
            setJustOrder(newOrders.map((item: Order)=> item.original_image_url))
          }
        });
        setOrders(orderMap)
      }
    }
  };

  const downloadOrderByFolder = async (folder: string) => {
      const { data = [] } = await getOderList(selectedRoute, userProfile.userId, folder);
      return data
  }

  

  const onClickImage = (image: CustomImage, index: number) => {
    setImageUrlForEdit("");
    setImageUrlForEdit(image.original);
    setImageIndex(index)
  };

  const loadMore = async (folder: string) => {
    const page = currentPage[folder] + 1;
    const oldImages = images[folder];
    const newImages = await downloadImageByFolder(folder, page);
    const newImageMap = { ...images, [folder]: [...oldImages, ...newImages] }
    setImages(newImageMap);
    setCurrentPage({ ...currentPage, [folder]: page });
    return newImageMap;
  };

  const changeImageEditUrl = (imageForEdit: ImageMap,newIndex: number) => {
    setImageUrlForEdit("");
    setIsLoading(true)
    setImageIndex(newIndex)
    setTimeout(() => {
      const image = imageForEdit[selectedFolder][newIndex]
      if(image){
        setImageUrlForEdit(image.original);
      }
      setIsLoading(false)
    }, 500);
  }

  const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
    setTab(newTab);
    if(newTab !== 2){
      setDefaultImage("")
    }
  };

  const handleNextLoadMore = async () => {
    setIsLoading(true)
    const newImages = await loadMore(selectedFolder)
    const newIndex = imageIndex + 1
    changeImageEditUrl(newImages, newIndex)
  }

  const handleNext = () => {
    if(imageIndex < images[selectedFolder].length - 1){
      const newIndex = imageIndex + 1
      changeImageEditUrl(images, newIndex)
    }else{
      if((currentPage[selectedFolder] * pageSize) < totalData[selectedFolder]){
        handleNextLoadMore()
      }
    }
  }

  const fillItemsAfterOrder = async (folder: string, imageForRemove: string) => {
    const current_page = currentPage[folder];
    const oldImages = images[folder].filter(image => image.original !== imageForRemove);
    const newImages = await downloadImageByFolder(folder, current_page);
    const imageForAdd = newImages.length > 0 ? newImages[newImages.length - 1] : null
    if(imageForAdd !== null ){
      const imageForSet = [...oldImages.filter(item=>item.original !== imageForAdd.original)]
      imageForSet.push(imageForAdd)
      const newImageMap = { ...images, [folder]:  imageForSet}
      setImages(newImageMap);
      return newImageMap
    }else{
      const newImageMap = { ...images, [folder]:  oldImages}
      setImages(newImageMap);
      return newImageMap
    }
  };

  const handleConfirmOrder = async (status: boolean, originalImageUrl: string, newOrder:Order) => {
    
    if(status){
      const newImageMap = await fillItemsAfterOrder(selectedFolder, originalImageUrl)
      const newIndex = imageIndex-1
      setImageIndex(newIndex)
      setCountEdited(countEdited + 1)
      setTotalData({ ...totalData, [selectedFolder]: totalData[selectedFolder] - 1 });
      setJustOrder([...justOrder, originalImageUrl])
      setOrders({ ...orders, [selectedFolder]: [newOrder, ...orders[selectedFolder]] });
      setTimeout(()=>{
        if(newIndex < newImageMap[selectedFolder].length - 1){
          changeImageEditUrl(newImageMap, newIndex + 1)
        }else{
          setImageUrlForEdit("");
        }
      },500)
    }
  }

  const scrollToImage = (imageSrc: string) => {
    console.log(imageSrc)
    const imageElement = document.querySelector(`img[src^="${imageSrc}"]`);
    console.log(imageElement)
    if (imageElement) {
      imageElement.scrollIntoView({ behavior: 'smooth', block: 'center', });
    }
  };

  return isLoading ? (
    <Loading />
  ) : (
    <>
      {
      imageUrlForEdit !== "" && <ModalEditImage
          imageUrl={imageUrlForEdit}
          handleClose={() => {
            const imageForScroll = imageUrlForEdit
            setImageUrlForEdit("");
            setTimeout(()=>{
              scrollToImage(imageForScroll)
            }, 300);
          }}
          handleConfirm={handleConfirmOrder}
          handleBack={()=>{
            if(imageIndex > 0){
              const newIndex = imageIndex - 1
              changeImageEditUrl(images, newIndex)
            }
          }}
          handleNext={handleNext}
          username={userProfile.displayName}
          groupId={userProfile.groupId}
          userId={userProfile.userId}
          profileUrl={userProfile.pictureUrl}
        />
      }
      {imageUrlForEdit === "" && <Box style={{ display: "flex", padding: '16px' }}>
        <Typography variant="h4">{groupName}</Typography>
      </Box>
      }

      {folders.map((item) => {
        return (
          <Card sx={{ padding: 2, marginY: 3, display: imageUrlForEdit === "" ? "block" : "none" }} key={item}>

            <CardContent sx={{ padding: '0px' }}>

              <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
                <Tab label="รายการ" {...a11yProps(0)} />
                <Tab label="สั่งซ้ำ" {...a11yProps(1)} />
                <Tab label="รายการที่สั่ง" {...a11yProps(2)} />
                <Tab label="ประวัติย้อนหลัง" {...a11yProps(3)} />
              </Tabs>
              <CustomTabPanel value={tab} index={0}>
                <Box style={{ display: "flex" }}>
                  <Typography variant="h5">
                    {item.replace("T", " ")} {` Loaded:${images[item]?.length || 0}/${totalData[item] || 0}`}
                  </Typography>
                </Box>
                <StandardImageList
                  itemData={images[item]}
                  onClickImage={(image: CustomImage, index: number)=>{
                    onClickImage(image, index);
                    setSelectedFolder(item)
                    setSelectedFolderToStorage(item)
                  }}
                />
                <Button
                  style={{ marginLeft: "auto" }}
                  color="primary"
                  size="medium"
                  onClick={() => {
                    loadMore(item);
                  }}
                  variant="contained"
                  sx={{ bgcolor: `primary.main` }}
                  disabled={(currentPage[item] * pageSize) >= totalData[item]}
                >
                  {`Load More: ${images[item]?.length || 0}/${totalData[item] || 0}`}
                </Button>
              </CustomTabPanel>
              <CustomTabPanel value={tab} index={1}>
                <UserOrderAgain orders={orders[item]} folder={item} />
              </CustomTabPanel>
              <CustomTabPanel value={tab} index={2}>
                <UserOrderHistory defaultImageUrl={defaultImage} countEdited={countEdited} route={selectedRoute} userId={userProfile.userId} username={userProfile.displayName} folder={item} userRole={userProfile.role} />
              </CustomTabPanel>
              <CustomTabPanel value={tab} index={3}>
                {allFolderInOrder.map(item=>{
                  return <UserOrderHistoryAll key={item+selectedRoute+userProfile.userId} countEdited={countEdited} route={selectedRoute} userId={userProfile.userId} username={userProfile.displayName} userRole={userProfile.role} folder={item} />;
                })}
              </CustomTabPanel>
            </CardContent>
          </Card>
        );
      })}

      {folders.length === 0 && (
        <Card sx={{ padding: 5, marginY: 3 }}>
          <Typography variant="subtitle2">No folder list</Typography>
        </Card>
      )}
    </>
  );
};

export default EditImage;
