export const redirectUrl = "/login";
export const liffId = "2001056122-04XVEbKA";
export const userId = "U263071e1f9574cd0b83f7d6b0b9ec277";
export const VIEW_ONLY_STATE = "VIEW_ONLY"
export const REJECTED_STATE = "rejected"
export const OPEN_STATE = "open"
export const CONFIRMED_STATE = "confirmed"
export const NONE_GROUP = "none_group"
export const ROUTE = {
  HOME: "/",
  SELECT_ROUTE: "/select-route",
  ADMIN: "/admin"
};

export const ROLES = {
  ADMIN: "admin",
  USER: "user",
  IMAGE_EDITED: "image_edited"
};
